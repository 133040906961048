import {
  Box,
  BoxProps,
  Checkbox,
  Flex,
  Input,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { usePlacesWidget } from "react-google-autocomplete";
import { useSegment } from "../../context/SegmentContext";
import KEYS from "../../keys";
import { Location } from "../../modules/Onboarding/EventTypes";
import { isInParisAgglomeration } from "../../utils/utils";
import { useState } from "react";

// function CustomInput({
//   address,
//   updateAddress,
//   disabled,
// }: {
//   address: string;
//   updateAddress: (adresse: string) => void;
//   disabled?: boolean;
// }) {
//   const { track } = useSegment();

//   const onPlaceSelected = (place: any) => {
//     if (!place?.formatted_address) return;
//     const postalCode = place.address_components.find((component: any) =>
//       component.types.includes("postal_code")
//     )?.short_name;
//     track("Address found by Google", {
//       adresse: place?.formatted_address,
//       postalCode,
//     });
//     updateAddress(place?.formatted_address);
//   };

//   const { ref } = usePlacesWidget<HTMLInputElement>({
//     apiKey: KEYS.GOOGLE_PLACES_API_KEY,
//     onPlaceSelected,
//     options: {
//       types: ["address"],
//       componentRestrictions: { country: "fr" },
//       language: "fr",
//     },
//   });

//   return (
//     <Input
//       ref={
//         // disable API if not in production (to avoid useless costs)
//         process.env.NODE_ENV === "production" ? ref : undefined
//         // ref
//       }
//       disabled={disabled}
//       placeholder="Renseignez l'adresse de votre événement"
//       maxW="400px"
//       mb="1rem"
//       value={address}
//       onChange={(e) => updateAddress(e.target.value)}
//     />
//   );
// }

function AddressFlowForm({
  eventLocations,
  updateAddress,
  address,
  setAddress,
  displayedCity,
  setDisplayedCity,
  displayedPostalCode,
  setDisplayedPostalCode,
}: // removeAddress,
{
  eventLocations: Location[];
  updateAddress: (
    index: number,
    adresse: string,
    postalCode: string,
    city: string
  ) => void;
  address: string;
  setAddress: (adresse: string) => void;
  displayedCity: string;
  setDisplayedCity: (displayedCity: string) => void;
  displayedPostalCode: string;
  setDisplayedPostalCode: (displayedPostalCode: string) => void;
  // removeAddress: (index: number) => void;
}) {
  const { track } = useSegment();
  const [error, setError] = useState("");

  const handlePostalCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (/^\d{0,5}$/.test(value)) {
      setDisplayedPostalCode(value);
      updateAddress(0, address, value, displayedCity);

      if (value.length === 5) {
        setError("");
      } else if (value.length > 0) {
        setError("Le code postal doit contenir 5 chiffres");
      } else {
        setError("");
      }
    } else {
      setError("Le code postal doit contenir 5 chiffres");
    }
  };

  const onPlaceSelected = (place: any) => {
    if (!place?.formatted_address) return;
    const zipCode = place.address_components.find((component: any) =>
      component.types.includes("postal_code")
    )?.short_name;
    const cityName = place.address_components.find((component: any) =>
      component.types.includes("locality")
    )?.short_name;
    track("Address found by Google", {
      adresse: place?.formatted_address,
      zipCode,
    });
    setAddress(
      place?.address_components[0]?.long_name +
        " " +
        place?.address_components[1]?.long_name
    );
    setDisplayedCity(cityName);
    setDisplayedPostalCode(zipCode);
    updateAddress(
      0,
      place?.address_components[0]?.long_name +
        " " +
        place?.address_components[1]?.long_name,
      zipCode,
      cityName
    );
  };

  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: KEYS.GOOGLE_PLACES_API_KEY,
    onPlaceSelected,
    options: {
      types: ["address"],
      componentRestrictions: { country: "fr" },
      language: "fr",
    },
  });

  const changeAddress = (
    index: number,
    address: string,
    postalCode: string,
    city: string
  ) => {
    setAddress(address);
    updateAddress(index, address, postalCode, city);
  };

  return (
    <>
      <Box>
        <Text fontWeight="bold" fontSize="20px">
          Numéro et nom de la rue
        </Text>
      </Box>
      {eventLocations.map((location, index) => {
        return (
          <Flex my="0.5rem" key={index}>
            {/* {eventLocations.length > 1 && (
              <Box
                border="1px solid black"
                borderRadius="100%"
                cursor="pointer"
                onClick={() => {
                  removeAddress(index);
                }}
                mt="10px"
                mr="10px"
                w="20px"
                h="20px"
                p="1px"
              >
                <Text mt="-6px" ml="5px">
                  x
                </Text>
              </Box>
            )} */}
            <Input
              // ref={process.env.NODE_ENV === "production" ? ref : undefined}
              ref={ref}
              disabled={
                location?.isAddressKnown === undefined
                  ? false
                  : !location?.isAddressKnown
              }
              placeholder="Renseignez l'adresse de votre événement"
              maxW="400px"
              value={
                location?.isAddressKnown === undefined ||
                location?.isAddressKnown
                  ? address
                  : "Je ne connais pas encore l’adresse."
              }
              onChange={(e) =>
                changeAddress(
                  index,
                  e.target.value,
                  displayedPostalCode,
                  displayedCity
                )
              }
            />
          </Flex>
        );
      })}

      <Flex>
        <Text fontWeight="bold" fontSize="20px">
          Ville
        </Text>
        <Text fontWeight="bold" fontSize="20px" color="red.500">
          *
        </Text>
      </Flex>
      <Flex my="0.5rem">
        <Input
          value={displayedCity}
          onChange={(e) => {
            if (displayedPostalCode !== "") setDisplayedPostalCode("");
            setDisplayedCity(e.target.value);
            updateAddress(0, address, displayedPostalCode, e.target.value);
          }}
          placeholder="Renseignez la ville de votre événement"
          maxW="400px"
        />
      </Flex>

      <Flex>
        <Text fontWeight="bold" fontSize="20px">
          Code Postal
        </Text>
        <Text fontWeight="bold" fontSize="20px" color="red.500">
          *
        </Text>
      </Flex>
      <Flex direction="column" my="0.5rem" mb="1rem">
        <Input
          value={displayedPostalCode}
          onChange={(e) => {
            handlePostalCodeChange(e);
          }}
          placeholder="Renseignez le code postal de votre événement"
          maxW="400px"
        />
        {error && (
          <div style={{ color: "red", marginTop: "0.5rem" }}>{error}</div>
        )}
      </Flex>
    </>
  );
}

export default function ChooseEventName({
  eventName,
  setEventName,
  eventLocations,
  setEventLocations,
  ...props
}: {
  eventName: string;
  setEventName: (eventName: string) => void;
  eventLocations: Location[];
  setEventLocations: (location: Location[]) => void;
} & BoxProps) {
  const [address, setAddress] = useState(eventLocations[0]?.address || "");
  const [displayedCity, setDisplayedCity] = useState(
    eventLocations[0]?.city || ""
  );
  const [displayedPostalCode, setDisplayedPostalCode] = useState(
    eventLocations[0]?.postalCode || ""
  );
  // const addAddress = () => {
  //   setEventLocations([
  //     ...eventLocations,
  //     { address: "", isInGreatParis: false, isAddressKnown: true },
  //   ]);
  // };
  // const removeAddress = (index: number) => {
  //   if (eventLocations.length === 1) return;
  //   let tmp = [...eventLocations];
  //   tmp.splice(index, 1);
  //   setEventLocations(tmp);
  // };

  const updateAddress = (
    index: number,
    adress: string,
    zipCode: string,
    cityName: string
  ) => {
    let tmp = [...eventLocations];
    if (
      adress === "Je ne connais pas encore l’adresse." &&
      zipCode !== "" &&
      cityName !== ""
    ) {
      tmp[index] = {
        address: zipCode + " " + cityName + ", France",
        isInGreatParis: isInParisAgglomeration(
          zipCode + " " + cityName + ", France"
        ),
        isAddressKnown: false,
        postalCode: zipCode,
        city: cityName,
      };
      setEventLocations(tmp);
    } else if (
      adress === "Je ne connais pas encore l’adresse." &&
      (zipCode === "" || cityName === "")
    ) {
      tmp[index] = {
        address: "Je ne connais pas encore l’adresse.",
        isInGreatParis: false,
        isAddressKnown: false,
        postalCode: zipCode,
        city: cityName,
      };
      setEventLocations(tmp);
    } else {
      tmp[index] = {
        address: adress + ", " + zipCode + " " + cityName + ", France",
        isInGreatParis: isInParisAgglomeration(
          adress + ", " + zipCode + " " + cityName + ", France"
        ),
        isAddressKnown: true,
        postalCode: zipCode,
        city: cityName,
      };
      setEventLocations(tmp);
    }
  };

  return (
    <Box textAlign="left" {...props}>
      <Flex>
        <Text fontWeight="bold" fontSize="20px">
          Nom de l'événement
        </Text>
        <Text fontWeight="bold" fontSize="20px" color="red.500">
          *
        </Text>
      </Flex>
      <Input
        placeholder="Renseignez un nom pour votre événement"
        maxW="400px"
        mb="1rem"
        value={eventName}
        onChange={(e) => setEventName(e.target.value)}
      />
      <AddressFlowForm
        eventLocations={eventLocations}
        // removeAddress={removeAddress}
        updateAddress={updateAddress}
        address={address}
        setAddress={setAddress}
        displayedCity={displayedCity}
        setDisplayedCity={setDisplayedCity}
        displayedPostalCode={displayedPostalCode}
        setDisplayedPostalCode={setDisplayedPostalCode}
      />
      <Tooltip
        label="Vous pourrez ajouter une adresse dans le brief final."
        placement="right-end"
        openDelay={100}
        closeDelay={200}
      >
        <Text
          onClick={
            () => {}
            // eventLocations[0]?.isAddressKnown === undefined
            //   ? addAddress
            //   : eventLocations[0].isAddressKnown
            //   ? addAddress
            //   : undefined
          }
          decoration={
            // eventLocations[0]?.isAddressKnown === undefined
            //   ? "underline"
            //   : eventLocations[0].isAddressKnown
            //   ? "underline"
            //   : "none"
            "underline"
          }
          width="fit-content"
          mb="0.5rem"
          cursor={
            // eventLocations[0]?.isAddressKnown === undefined
            //   ? "pointer"
            //   : eventLocations[0].isAddressKnown
            //   ? "pointer"
            //   : "not-allowed"
            "pointer"
          }
          color={
            // eventLocations[0]?.isAddressKnown === undefined
            //   ? "black"
            //   : eventLocations[0].isAddressKnown
            //   ? "black"
            //   : "gray.300"
            "black"
          }
        >
          + Ajouter une adresse
        </Text>
      </Tooltip>

      <Flex>
        <Checkbox
          marginTop="0.5rem"
          isChecked={
            eventLocations[0].isAddressKnown === undefined
              ? false
              : !eventLocations[0].isAddressKnown
          }
          onChange={() => {
            eventLocations[0].isAddressKnown === undefined
              ? updateAddress(
                  0,
                  "Je ne connais pas encore l’adresse.",
                  displayedPostalCode,
                  displayedCity
                )
              : !eventLocations[0].isAddressKnown
              ? updateAddress(0, address, displayedPostalCode, displayedCity)
              : updateAddress(
                  0,
                  "Je ne connais pas encore l’adresse.",
                  displayedPostalCode,
                  displayedCity
                );
          }}
        >
          <Text fontWeight="bold">
            Je ne connais pas encore l'adresse exacte.
          </Text>
          <Text fontSize="sm" color="gray.500">
            (Les frais de déplacement seront rattrapés dans la facture de solde)
          </Text>
        </Checkbox>
      </Flex>
    </Box>
  );
}
